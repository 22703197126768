import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button1 from '../../components/Buttons/Button1';
import Input1 from '../../components/Inputs/Input1';

import Logo from "../../assets/icons/logo-white.png";

import "./styles.scss";
import { useDispatch } from 'react-redux';
import { LogIn, Register } from '../../redux/actions/authActions';

// login form
let loginForm = {
  Email: "",
  Password: ""
}

// register form
let registerForm = { 
  Email: "",
  FirstName: "",
  LastName: "",
  Password: ""
}

const AuthPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(loginForm);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if(location.pathname === "/auth"){
      setFormData(loginForm);
    }else if(location.pathname === "/register"){
      setFormData(registerForm);
    }else {
      console.log("Invalid page");
      navigate("/auth");
    }
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleLogin = () => {
    console.log("login", formData);
    let obj = {
      Email: formData.Email,
      Password: formData.Password
    }
    dispatch(LogIn(obj, navigate, setLoading));
  }

  const handleRegister = () => {
    let obj = {
      Email: formData.Email,
      Password: formData.Password,
      FirstName: formData.FirstName,
      LastName: formData.LastName
    }

    let loginObj = {
      Email: formData.Email,
      Password: formData.Password
    }

    dispatch(Register(obj, setLoading)).then((res) => {
      console.log(res)
      
        setTimeout(() => {
          dispatch(LogIn(loginObj, navigate, setLoading))
        }, 1000);

    })
  }


  return (
    <div className='auth'>
        <div className='auth__banner flexCenterCenterRow'>
          <img
            src={Logo}
            alt='logo'
            style={{ width: "70%" }}
            />
        </div>
        <div className='auth__form'>
          {
            location.pathname === "/auth"
            ?
            <div className='auth__form-box'>
              <div className='auth__form-title'>Login into your account</div>
              <Input1
                name={"Email"}
                label={"Email"}
                placeholde={"Enter your email"}
                value={formData.Email}
                onChange={handleChange} 
                />
              <Input1
                name={"Password"}
                label={"Password"}
                type={"password"}
                placeholde={"Enter your password"}
                value={formData.Password}
                onChange={handleChange} 
                />
              <Button1 
                label={"Login"}
                onClick={handleLogin}
                type={2}
                fullWidth={true}
                loading={loading}
                disabled={!(formData.Email !== "" && formData.Password !== "")}
                />
              <div
                className='auth__form-subText'
                onClick={() => {
                  setFormData(loginForm);
                  navigate("/register");
                }}>
                <i>Don't have an account?</i>
              </div>
            </div>
            :
              location.pathname === "/register"
              ?
              <div className='auth__form-box'>
                <div className='auth__form-title'>Create new account</div>
                <Input1
                  name={"Email"}
                  label={"Email"}
                  placeholde={"Enter your email"}
                  value={formData.Email}
                  onChange={handleChange} 
                  />
                 <Input1
                  name={"FirstName"}
                  label={"First name"}
                  placeholde={"Enter your first name"}
                  value={formData.FirstName}
                  onChange={handleChange} 
                  />
                <Input1
                  name={"LastName"}
                  label={"Last name"}
                  placeholde={"Enter your last name"}
                  value={formData.LastName}
                  onChange={handleChange} 
                  />
                <Input1
                  name={"Password"}
                  label={"Password"}
                  placeholde={"Enter your password"}
                  value={formData.Password}
                  onChange={handleChange} 
                  />
                <Button1 
                  label={"Register"}
                  onClick={handleRegister}
                  type={2}
                  fullWidth={true}
                  loading={loading}
                  disabled={!(formData.Email !== "" && 
                    formData.Password !== "" && 
                    formData.FirstName !== "" && 
                    formData.LastName !== "" 
                    )}
                  />
                 <div
                  className='auth__form-subText'
                  onClick={() => {
                    setFormData(registerForm);
                    navigate("/auth");
                  }}>
                  <i>Already have an account?</i>
                </div>
              </div>
              :
              "Invalid page"
          }

        </div>
    </div>
  )
}

export default AuthPage