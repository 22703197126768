import React from "react";
import Router from "./routes/routes";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import styles from "./global.scss";

const theme = createTheme({
  typography: {
    fontFamily: "urbanist, sans-serif"
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontWeight: "600"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
        }
      }
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Router />
      </div>
    </ThemeProvider>
  );
}

export default App;
