import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  TableFooter,
  TablePagination,
  IconButton,
  CircularProgress,
  Tooltip,
  Button
} from '@mui/material';

import TopBar1 from '../../components/topBar/TopBar1';
import TablePaginationActions from '../../components/Utils/TablePaginationActions';

import { 
  DeleteVCN,
  GetAllVCN, GetVCNDetails 
} from '../../redux/actions/virtualCardsActions';

import MasterCardLogo from "../../assets/icons/Mastercard_logo.svg";
import HDFCLogo from "../../assets/icons/hdfc-logo.png";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import VirtualCardsModal from './VirtualCardsModal';

import styles from "../../global.scss";
import "./styles.scss";


const VirtualCardsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.vcn);
  const [loading, setLoading] = useState(false);  
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [virtualCardsModalOpen, setVirtualCardsModalOpen] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const [selectedVcn, setSelectedVcn] = useState(null);

  const [vcnList, setVcnList] = useState([]);
  const [vcnTotal, setVcnTotal] = useState(0);

  const [vcnDetails, setVcnDetails] = useState(null);

  const [ccNumCopied, setCCNumCopied] = useState(false);
  const [cvvCopied, setCVVCopied] = useState(false);
 
  // ------------- table pagination ----------------
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);  
  };

  useEffect(() => {
    dispatch(GetAllVCN(page+1, rowsPerPage, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page, rowsPerPage]);

  useEffect(() => {
    console.log(state)
    try{
      formatVcnList(state.vcnList.Records || []);
      setVcnTotal(state.vcnList.Total || 0);

      formatVcnDetails(state.vcnDetails);
    }catch(e){}
  },[state]);

  // ----------------- format rows--------------
  const formatVcnList = (list) => {
    setCCNumCopied(false);
    setCVVCopied(false);
    setVcnList(list)
  }

  const formatVcnDetails = (obj) => {
    if(selectedVcn != null) {
      setVcnDetails({
        ...obj,
        deleted: vcnList.find(i => i.Id == selectedVcn).Status === "Active" ? false : true
      });
    }
  }

  // ----------------- miscellaneous -----------
  const getVCNDetails = (id) => {
    setSelectedVcn(id);
    setCCNumCopied(false);
    setCVVCopied(false);
    dispatch(GetVCNDetails(id, setLoadingDetails));
  }

  const deleteVcn = (id) => {
    setCCNumCopied(false);
    setCVVCopied(false);
    dispatch(DeleteVCN(id, setLoadingDetails)).then(() => {
      setSelectedVcn(null);
      setVcnDetails(null);
      dispatch(GetAllVCN(page+1, rowsPerPage, setLoading));
    })
  }

  return (
    <div>
      <TopBar1 
        title={"Virtual Cards"}
        btnLabel={"Create New Card"}
        onClick={() => {
          setModalTitle("Create virtual card");
          setVirtualCardsModalOpen(!virtualCardsModalOpen);
        }}
        />

    <VirtualCardsModal 
      open={virtualCardsModalOpen}
      setOpen={setVirtualCardsModalOpen}
      title={modalTitle}
      page={page}
      rowsPerPage={rowsPerPage}
      />

      <div className='flexFSSBRow'>
        <div className='vcn' style={{ width: selectedVcn === null ? "100%" : "50%"}}>
        {
          loading
          ?
          <div className='flexCenterCenterRow' style={{ minHeight: "200px" }}>
            <CircularProgress fontSize={60}/>
          </div>
          :
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell sx={{ color: "#8f8f8f", fontSize: 13}}>Description</TableCell> */}
                {/* <TableCell sx={{ color: "#8f8f8f", fontSize: 13}}>Currency</TableCell> */}
                <TableCell sx={{ color: "#8f8f8f", fontSize: 13}}>Amount</TableCell>
                <TableCell sx={{ color: "#8f8f8f", fontSize: 13}}>Last 4 Digits</TableCell>
                <TableCell sx={{ color: "#8f8f8f", fontSize: 13}}>Status</TableCell>
                <TableCell sx={{ color: "#8f8f8f", fontSize: 13}}>Linked to</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                vcnList.length === 0
                ?
                <TableRow>
                  <TableCell component="th" sx={{ fontSize: 13, fontWeight: 500, textAlign: 'center', borderBottom: 0}} colSpan={4}>
                    No Vcn Found
                  </TableCell>
                </TableRow>
                :
                vcnList?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  className={`${selectedVcn === row.Id ? "vcn__row--selected" : "vcn__row"}`}
                  onClick={() => getVCNDetails(row.Id)}
                >
                  {/* <TableCell component="th" sx={{ fontSize: 13, fontWeight: 500 }}>{row.Description || "NA"}</TableCell> */}
                  {/* <TableCell component="th" sx={{ fontSize: 13, fontWeight: 500 }}>{row.Currency}</TableCell> */}
                  <TableCell component="th" sx={{ fontSize: 13, fontWeight: 500 }}>$ {row.Amount}</TableCell>
                  <TableCell component="th" sx={{ fontSize: 13, fontWeight: 500 }}>{row.Last4Digits}</TableCell>
                  <TableCell component="th" sx={{ fontSize: 13, fontWeight: 500 }}>{row.Status}</TableCell>
                  <TableCell component="th" sx={{ fontSize: 13, fontWeight: 500 }}>
                    <div>
                      xxxx {row.RealCard && row.RealCard.Last4Digits} <i>(HDFC Bank)</i>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0}}
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  colSpan={10}
                  count={vcnTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        }
        </div>
        {
          vcnDetails != null
          &&
          <div style={{ width: "50%" }}>
            { 
            loadingDetails
            ?
            <div className='flexCenterCenterRow' style={{ minHeight: "200px" }}>
              <CircularProgress fontSize={60}/>
            </div>
            :
            <div className='flex_FSColumn'>
              <div 
                style={{ 
                  width: "80%",
                  margin: "auto",
                  padding: "20px",
                  marginTop: "20px",
                  borderRadius: "12px",
                  backgroundColor: "#fafafa" }}>
                <div
                  style={{ 
                    margin: "-20px", 
                    marginBottom: "20px", 
                    padding: "20px", 
                    fontWeight: "600",
                    backgroundColor: styles["primary_light"],
                    borderRadius: "12px 12px 0px 0px"}}>
                  Card Details
                </div>
                <div>
                  Single use Virtual Card<br/>
                  Generated via Lusid Browser Extension 1.0.3<br/>
                  {
                    vcnDetails.Description[0] !== '{' 
                    &&
                    <div>
                      Description: {vcnDetails.Description || "NA"}
                    </div>
                  }
                </div>
                {/* Custom Fields:  */}
                {
                  vcnDetails.Description[0] === '{'
                  &&
                  <div style={{ marginTop: "10px", marginBottom: "-10px"}}>
                    <div className='flexFSSBRow' style={{ marginBottom: "5px" }}>
                      <div style={{ width: "160px" }}>Hotel Name:</div>
                      <div style={{ fontWeight: "600", width: "100%" }}>{JSON.parse(vcnDetails.Description).hn}</div>
                    </div>
                    <div className='flexFSSBRow' style={{ marginBottom: "5px" }}>
                      <div style={{ width: "160px" }}>Hotel Address:</div>
                      <div style={{ fontWeight: "600", width: "100%" }}>{JSON.parse(vcnDetails.Description).ha}</div>
                    </div>
                    <div className='flexFSSBRow' style={{ marginBottom: "5px" }}>
                      <div style={{ width: "160px" }}>Check in:</div>
                      <div style={{ fontWeight: "600", width: "100%" }}>{JSON.parse(vcnDetails.Description).hci}</div>
                    </div>
                    <div className='flexFSSBRow' style={{ marginBottom: "5px" }}>
                      <div style={{ width: "160px" }}>Check out:</div>
                      <div style={{ fontWeight: "600", width: "100%" }}>{JSON.parse(vcnDetails.Description).hco}</div>
                    </div>
                    {/* <div className='flexFSSBRow' style={{ marginBottom: "5px" }}>
                      <div style={{ width: "160px" }}>Booker Name:</div>
                      <div style={{ fontWeight: "600", width: "100%" }}>{JSON.parse(vcnDetails.Description).bn}</div>
                    </div> */}
                  </div>
                }
              </div>

              <div
                className='card flex_SBColumn' >
                <div className='card__top flexCenterSBRow'>
                  <div>
                    $ {vcnDetails.Amount || ""}
                  </div>  
                  <img
                    src={HDFCLogo}
                    alt="hdfc-logo"
                    style={{ width: "60px" }}
                    />
                </div>
                <div className='card__number'>
                  {vcnDetails.Pan ? vcnDetails.Pan.slice(0,4) + " " + vcnDetails.Pan.slice(4,8) + " " + vcnDetails.Pan.slice(8, 12) + " " + vcnDetails.Pan.slice(12, 16) : "NA"}
                  <Tooltip title={ccNumCopied ? "Copied CC Number" :"Copy CC Number"}>
                    <IconButton
                      sx={{ marginTop: "-5px", padding: 1}}
                      onClick={() => {
                        setCCNumCopied(true);
                        setCVVCopied(false);
                        navigator.clipboard.writeText(vcnDetails.Pan || "0");
                      }}>
                      {
                        ccNumCopied
                        ?
                        <ContentCopyTwoToneIcon />
                        :
                        <ContentCopyIcon sx={{ color: "black" }}/>
                      }
                    </IconButton>
                  </Tooltip>
                </div>
                
                <div className='flexCenterSBRow'>
                  <div className='flexCenterCenterRow'>
                    Expiry: &nbsp;
                    <div className='card__expiry'>{vcnDetails.Expiry ? vcnDetails.Expiry[0] + vcnDetails.Expiry[1] + "/" + vcnDetails.Expiry[2] + vcnDetails.Expiry[3] : "NA"}</div>
                  </div>
                  <div className='flexCenterCenterRow'>
                    CVV: &nbsp;
                    <div className='card__cvv'>{vcnDetails.Avv || "NA"}</div>
                    <Tooltip title={cvvCopied ? "Copied CCV" :"Copy CCV"}>
                      <IconButton
                        sx={{ marginTop: "-5px", padding: 1}}
                        onClick={() => {
                          setCVVCopied(true);
                          setCCNumCopied(false);
                          navigator.clipboard.writeText(vcnDetails.Avv || "0");
                        }}>
                        {
                          cvvCopied
                          ?
                          <ContentCopyTwoToneIcon />
                          :
                          <ContentCopyIcon sx={{ color: "black" }}/>
                        }
                      </IconButton>
                    </Tooltip>  
                  </div>

                </div>
                  <div className='flexCenterSBRow'>
                    <div className='flexCenterEndRow'>
                      Linked to: &nbsp;
                      {/* <div className='card__cvv'>{card.Currency || "NA"}</div>
                      &nbsp; */}
                      <div className='card__cvv'>xxxx{vcnDetails?.RealCard?.Last4Digits || "NA"}</div>
                    </div>
                    <img
                      src={MasterCardLogo}
                      alt="master-card-logo"
                      style={{ width: "40px" }}
                      />
                  </div>
              </div>


              {
                !vcnDetails.deleted
                &&
                <div
                  style={{ margin: "auto", marginTop: "30px"}}>
                  <Button
                    color='error'
                    onClick={() => {
                      deleteVcn(vcnDetails.Id)
                    }}>
                    <DeleteOutlineIcon color='error' />
                    Delete Card
                  </Button>
                </div>
              }
            </div>
            }
          </div>
        }
      </div>
    </div>
  )
}

export default VirtualCardsPage