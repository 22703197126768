import React from 'react';

import "./styles.scss";
import Button1 from '../Buttons/Button1';

const TopBar1 = (props) => {
  return (
    <div className='topbar'>
      <div className='topbar__title'>
        {props.title || ""}
      </div>

      <div className='topbar__btn'>
        <Button1 
          label={props.btnLabel || "NA"}
          onClick={props.onClick}
          />
      </div>
    </div>
  )
}

export default TopBar1