import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button1 from '../../components/Buttons/Button1';
import Logo from "../../assets/icons/logo.png";

import WaveAnim from './WaveAnim';

import "./styles.scss";

const LandingPage = () => {
  const navigate = useNavigate();

  return (  
    <div className='main'>
      <div  className='main__content'>
        <img  
          src={Logo}
          alt="Lusid"
          width={400}
          className='main__content--title'
          />
        <Button1
          label={"Sign In"}
          onClick={() => navigate("/auth")} 
          type={2}
          />
      </div>

      <WaveAnim />
    </div>
  )
}

export default LandingPage;