import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { 
  IconButton, 
  Menu, 
  MenuItem ,
  Avatar
} from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone';
import PaymentTwoToneIcon from '@mui/icons-material/PaymentTwoTone';

import Logo from "../../assets/icons/logo.png";
import MiniLogo from "../../assets/icons/mini-logo.png";

import styles from "../../global.scss";
import "./styles.scss";

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    var route = location.pathname.split("/")
    // var module = route[1];
    var page = route.splice(1).join("/");
    
    console.log(route)

    switch(page){
      // case "dashboard": return setCurrentPage(0);
      case "cards": return setCurrentPage(1);
      case "virtual-cards": return setCurrentPage(2);
      // case "transactions": return setCurrentPage(3);

      default: return setCurrentPage(0);
    }

  }, [currentPage, location.pathname]);

  const logoutHandler = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
    return null;
  }

    
  return (
    <div className='nav'>
      <div className='nav__topbar'>
        <div className='nav__topbar-left'>
          {/* <div>Lusid</div> */}
          <img
            src={MiniLogo}
            alt="Lusid"
            width={70}
            style={{ padding: "10px" }}
            />
        </div>
        <div className='nav__topbar-right'>
          Raj Malhotra
        </div>
      </div>

      <div className='nav__content'>
        <div className='nav__content-left'>
          <div className='nav__content-left-top'>
            {/* <div className={`${currentPage === 0 ? 'nav__content-left-selected'  :'nav__content-left-item' }`}
              onClick={() => {
                setCurrentPage(0); 
                navigate("/dashboard");
              }}>
              <DashboardTwoToneIcon 
                sx={{ 
                  backgroundColor: styles[currentPage === 0 ? "secondary_light" : ""],
                  color: styles[currentPage === 0 ? "white": "secondary_dark"], 
                  transition: "all 0.25s",
                  paddingTop: "5px",
                  paddingRight: "10px",
                  paddingBottom: "5px",
                  paddingLeft: "10px",
                  width: "80px",
                  height: "40px",
                  borderRadius: "25px"
                }}/>
                Dashboard
            </div> */}

            <div className={`${currentPage === 1 ? 'nav__content-left-selected'  :'nav__content-left-item' }`}
              onClick={() => {
                setCurrentPage(1); 
                navigate("/cards");
              }}>
              <PaymentTwoToneIcon 
                sx={{ 
                  // backgroundColor: styles[currentPage === 1 ? "secondary_light" : ""],
                  color: styles[currentPage === 1 ? "primary_dark": "secondary_dark"], 
                  transition: "all 0.25s",
                  paddingTop: "5px",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                  width: "80px",
                  height: "40px",
                }}/>
                Real<br/>Cards
            </div>

            <div className={`${currentPage === 2 ? 'nav__content-left-selected'  :'nav__content-left-item' }`}
              onClick={() => {
                setCurrentPage(2); 
                navigate("/virtual-cards");
              }}>
              <LocalAtmTwoToneIcon 
                sx={{ 
                  // backgroundColor: styles[currentPage === 2 ? "secondary_light" : ""],
                  color: styles[currentPage === 2 ? "primary_dark": "secondary_dark"], 
                  transition: "all 0.25s",
                  paddingTop: "5px",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                  width: "80px",
                  height: "40px",
                }}/>
                Virtual<br/>Cards
            </div>

            {/* <div className={`${currentPage === 3 ? 'nav__content-left-selected'  :'nav__content-left-item' }`}
              onClick={() => {
                setCurrentPage(3); 
                navigate("/transactions");
              }}>
              <ReceiptTwoToneIcon 
                sx={{ 
                  backgroundColor: styles[currentPage === 3 ? "secondary_light" : ""],
                  color: styles[currentPage === 3 ? "white": "secondary_dark"], 
                  transition: "all 0.25s",
                  paddingTop: "5px",
                  paddingRight: "10px",
                  paddingBottom: "5px",
                  paddingLeft: "10px",
                  width: "80px",
                  height: "40px",
                  borderRadius: "25px"
                }}/>
                Transactions
            </div> */}

            {/* <div className={`${currentPage === 3 ? 'nav__content-left-selected'  :'nav__content-left-item' }`}
              onClick={() => {
                setCurrentPage(3); 
                navigate("/transactions");
              }}>
              <LocalAtmTwoToneIcon 
                sx={{ 
                  backgroundColor: styles[currentPage === 3 ? "secondary_light" : ""],
                  color: styles[currentPage === 3 ? "white": "secondary_dark"], 
                  transition: "all 0.25s",
                  paddingTop: "5px",
                  paddingRight: "10px",
                  paddingBottom: "5px",
                  paddingLeft: "10px",
                  width: "80px",
                  height: "40px",
                  borderRadius: "25px"
                }}/>
                Pay
            </div> */}
          </div>
            
          
          <div className='nav__content-left-bottom'>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
              open={open}
              sx={{
                '& .MuiPaper-root': {
                  borderRadius: "16px",
                }}}
              onClose={() => setAnchorEl(null)}
              disableScrollLock={true}
              MenuListProps={{
                'aria-labelledby': 'basic',
              }}
            >
              <MenuItem onClick={logoutHandler} 
                style={{ height: "48px", fontFamily: "urbanist" }}>
                <LogoutIcon sx={{ marginRight: "16px"}}/>
                Sign Out
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className='nav__content-right'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Navigation