//ERRORS
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
//MESSAGE
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
//AUTH
export const LOG_IN = "LOG_IN";
export const REGISTER = "REGISTER";
export const LOG_OUT = "LOG_OUT";

//CARDS
export const GET_CARDS = 'GET_CARDS';

//VIRTUAL CARDS
export const GET_VCNS = 'GET_VCNS';
export const GET_VCN_DETAILS = 'GET_VCN_DETAILS';

//MISCELLANOUES
export const GET_ALL_CURRENCIES = "GET_ALL_CURRENCIES";