import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Autocomplete,
  Divider,
  IconButton,
  MenuItem
} from "@mui/material";

import { 
  CreateVCN, 
  GetAllVCN 
} from "../../redux/actions/virtualCardsActions";

import Button1 from "../../components/Buttons/Button1";
import Input1 from "../../components/Inputs/Input1";

import styles from "../../global.scss";
import { GetCards } from "../../redux/actions/cardsActions";

const theme = createTheme({
  palette: {
    primary: {
      main: styles["secondary_dark"]
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "250px",
          marginTop: "10px", 
          marginBottom: "10px",
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "14px"
        }
      }
    }
  }
});

let vcnForm = {
  Currency: "USD",
  Amount: 0,
  Description: "",
  RealCardId: ""
}

const VirtualCardsModal = ({ open, setOpen, title, page, rowsPerPage }) => {
  const descriptionElementRef = React.useRef(null);
  const scroll = "paper";
  const dispatch = useDispatch();
  const state = useSelector(state => state.card);
  const [enableSave, setEnableSave] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(vcnForm);

  const [cardsList, setCardsList] = useState([]);

  useEffect(() => {
    console.log(state);
    try { 
      formatCardsList(state.cardsList.Records || []);
    } catch (err) {}
  }, [state]);

  // ------------------ list formatters ------------------
  const formatCardsList = (list) => {
    setCardsList(list.filter(i => i.Currency !== null));
  }

  // ------------------ open & close ----------------------------  
  useEffect(() => {
    setFormData(vcnForm);
    if(open){
      dispatch(GetCards(1, 1000, setLoading))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setEnableSave(false);
    setOpen(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = () => {
    let obj = {
      Currency: formData.Currency,
      Amount: formData.Amount,
      Supplier: "test",
      RealCardId: formData.RealCardId,
      Description: formData.Description
    }
 // {
//     "Currency":"INR",
//     "Amount":5,
//     "Supplier":"swiggy.com",
//     "RealCardId": "ec8f1979-67ae-452b-934e-4cb98caf8a73"
// }
    dispatch(CreateVCN(obj, setLoading)).then(() => {
      dispatch(GetAllVCN(page+1, rowsPerPage, setLoading)).then(() => {
        handleClose();
      })
    })
  }

  
  return (
  // <ThemeProvider theme={theme}>
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        style: { borderRadius: 20 }}}
    >
      <DialogTitle id="scroll-dialog-title">
        <span style={{ fontSize: 18, fontWeight: 500, fontFamily: "Urbanist" }}>{title}</span>
      </DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ whiteSpace: "pre-wrap" }} id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <Divider sx={{marginBottom: "15px"}}/>
          <div style={{color: "black", fontSize: 14 }}>
            <div style={{gap: "40px", height: "300px" }}>
              <div className="flexCenterSBRow" style={{ gap: "20px",  marginBottom: "20px" }}>
                <Input1
                  name={"Currency"}
                  label={"Currency"}
                  disabled={true}
                  value={formData.Currency}
                  onChange={handleChange} 
                  />
                <Input1
                  name={"Amount"}
                  label={"Amount"}
                  type={"number"}
                  value={formData.Amount}
                  onChange={handleChange} 
                  />
              </div>
              <div className="flexCenterSBRow" style={{ gap: "20px" }}>
                <Input1
                  name={"Description"}
                  label={"Description (optional)"}
                  value={formData.Description}
                  onChange={handleChange} 
                  />
                {/* <Input1
                  name={"RealCardId"}
                  label={"Real Card"}
                  value={formData.RealCardId}
                  onChange={handleChange} 
                  /> */}

                <Input1
                  name={"RealCardId"}
                  label="Real Card"
                  select={true}
                  // fullWidth={true}
                  value={formData.RealCardId}
                  onChange={handleChange}>
                  {
                    cardsList.length === 0 
                    ?
                    <MenuItem disabled>No Cards Found</MenuItem>
                    :
                    cardsList.map((item, index) => (
                      <MenuItem key={index} value={item.Id}>
                        xxxx{item.Last4Digits} - {item.Currency}
                      </MenuItem>
                    ))
                  }
                </Input1>
              </div>
            </div>
          </div>
          <div className="flexCenterCenterRow" style={{ margin: "auto", width: "250px",  gap: "20px", marginTop: "40px" }}>
            <Button1 
              label={"Cancel"} 
              onClick={() => handleClose()}
              type={1}
              />
            <Button1
              label={"Submit"}
              onClick={handleSubmit} 
              disabled={!(formData.Currency !== "" && 
                formData.Amount !== "" && 
                formData.RealCardId !== "" && 
                formData.Supplier !== "" 
              )}
              loading={loading}
              type={2}
              />
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  // </ThemeProvider>
  );
};

export default VirtualCardsModal;