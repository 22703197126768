import axios from "axios";

const API = axios.create({
  // baseURL:  `${process.env.REACT_APP_BASE_URL}/`,
  baseURL:  ``,
});

API.interceptors.request.use((req) => {
  if (sessionStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(sessionStorage.getItem("user"))?.refreshToken}`;
  }
  return req;
});

export const login = (form) => API.post(`https://api.lusid.ai/apps/auth2/user/login`, form); 
export const register = (form) => API.post(`https://api.lusid.ai/apps/auth2/user/register`, form);

// cards
export const getCards = (page, limit) => API.get(`https://api.lusid.ai/apps/payments/user/cards?page=${page}&limit=${limit}`);
// query parameter of Status for filtering
export const requestCard = () => API.post(`https://api.lusid.ai/apps/payments/user/cards`);
export const updateCard = (id, form) => API.put(`https://api.lusid.ai/apps/payments/user/cards/activate/${id}`, form);
// {
//     "Currency":"INR",
//     "Limit": 50000,
//     "Last4Digits": "5987"
// }
export const toggleCard = (id) => API.put(`https://api.lusid.ai/apps/payments/user/cards/activate/${id}`);

// virtual cards
export const getAllVCN = (page, limit) => API.get(`https://api.lusid.ai/apps/payments/user/vcn?page=${page}&limit=${limit}`);
// query parameters of Status, RealCardId for filtering
export const getVCNDetails = (id) => API.get(`https://api.lusid.ai/apps/payments/user/vcn/${id}`);
export const createVCN = (form) => API.post(`https://api.lusid.ai/apps/payments/user/vcn`, form);
export const deleteVCN = (id) => API.delete(`https://api.lusid.ai/apps/payments/user/vcn/${id}`);

// transactions
// write coming soon... on it.

// miscellaneous
export const getAllCurrencies = () => API.get(`https://api.lusid.ai/apps/payments/currencies/all?page=1&limit=1000`);
export const getCurrencyByCode = (code) => API.get(`https://api.lusid.ai/apps/payments/currencies/${code}`);