import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const GetAllVCN = (page, limit, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getAllVCN(page, limit);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_VCNS, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error("");
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const GetVCNDetails = (id, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getVCNDetails(id);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_VCN_DETAILS, payload: data.vcn });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error("");
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const CreateVCN = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.createVCN(formData);
    console.log(data, status);
    if (status === 200) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error("");
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const DeleteVCN = (id, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.deleteVCN(id);
    console.log(data, status);
    if (status === 200) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error("");
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};