import React, { useEffect, useRef } from 'react';

import * as THREE from 'three';
import { createNoise2D, createNoise3D, createNoise4D } from 'simplex-noise';
import chroma from "chroma-js";

import { GUI } from 'dat.gui';

const WaveAnim = () => {
  const animRef = useRef(null);

  useEffect(() => {

    let conf = {
      fov: 75,
      cameraZ: 75,
      xyCoef: 30,
      zCoef: 12,
      lightIntensity: 0.9,
      ambientColor: 0xf1f1f1,
      light1Color: 0x0E09DC,
      light2Color: 0x1CD1E1,
      light3Color: 0x18C02C,
      light4Color: 0xee3bcf
    };

    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera(70);

   

    var renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    // var renderer = new THREE.WebGLRenderer({ canvas: document.getElementById("anim"), antialias: true, alpha: true });
    renderer.setSize( window.innerWidth ,700 );
    renderer.setClearColor(0x000000, 0);
    document.getElementById("anim").innerHTML = "";
    document.getElementById("anim").appendChild( renderer.domElement );

    camera.position.z = 2;

    var light, light1, light2, light3, light4;
    var gArray;
    var plane;
    var simplex = new createNoise4D();
  
    const mouse = new THREE.Vector2();
    const mousePlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
    const mousePosition = new THREE.Vector3();
    const raycaster = new THREE.Raycaster();
  
    const noiseInput = 40;
    const heightInput = 10;


    initScene();

    // const gui = new GUI();
    // animate();
    // camera.position.z = conf.cameraZ;
    
    // updateSize();
    // window.addEventListener('resize', updateSize, false);

    // document.addEventListener('mousemove', e => {
    //   const v = new THREE.Vector3();
    //   camera.getWorldDirection(v);
    //   v.normalize();
    //   mousePlane.normal = v;
    //   mouse.x = e.clientX / width * 2 - 1;
    //   mouse.y = -(e.clientY / height) * 2 + 1;
    //   raycaster.setFromCamera(mouse, camera);
    //   raycaster.ray.intersectPlane(mousePlane, mousePosition);
    // });

    // initGui();

    // const geometry = new THREE.BoxGeometry( 1, 1, 1 );
    // const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
    // const cube = new THREE.Mesh( geometry, material )
    // scene.add( cube );
    


    // function animate() {
    //   requestAnimationFrame( animate );
    //   cube.rotation.x += 0.01;
    //   cube.rotation.y += 0.01;
    //   renderer.render( scene, camera );

    // }


   
    // const TMath = THREE.Math;
  

  
    // init();
  
    // function init() {

    // }
  
    // function initGui() {
    //   noiseInput.value = 50
    //   heightInput.value = 10;
  
    //   // noiseInput.addEventListener('input', e => {
    //   //   conf.xyCoef = 101 - noiseInput.value;
    //   // });
    //   // heightInput.addEventListener('input', e => {
    //   //   conf.zCoef = heightInput.value * 25 / 100;
    //   // });
  
    //   // document.getElementById('trigger').addEventListener('click', e => {
    //   //   updateLightsColors();
    //   // });
    // }
  
    function initScene() {
      initLights();
      // let mat = new THREE.MeshLambertMaterial({ color: 0xffffff, side: THREE.DoubleSide });
      // // let mat = new THREE.MeshPhongMaterial({ color: 0xffffff });
      // // let mat = new THREE.MeshStandardMaterial({ color: 0x808080, roughness: 0.5, metalness: 0.8 });
      // let geo = new THREE.PlaneGeometry(800, 200, 1000 / 2, 300 / 2);
      // plane = new THREE.Mesh(geo, mat);
      // scene.add(plane);
      
      // let material = new THREE.MeshStandardMaterial({ color: 0xFFFFFF, roughness: 0.7, metalness: 0.6 });
      let material = new THREE.MeshLambertMaterial({ color: 0xffffff, side: THREE.DoubleSide });
      // var material = new THREE.MeshBasicMaterial({ color: 0xff00ff, side: THREE.DoubleSide });
      let geometry = new THREE.PlaneGeometry(150, 100, 150, 100);
      plane = new THREE.Mesh( geometry, material );
      plane.position.set(0,0,0);
      scene.add( plane );

      // plane.rotation.x = -Math.PI / 2 - 0.2;
      // plane.rotation.x = -Math.PI / 2 - 1.6;
      // // plane.position.y = 0;
      // // plane.rotation.x = -Math.PI / 2 - 0.2;
      // plane.position.y = -25;
      // camera.position.z = 200;


      plane.rotation.x = -Math.PI / 2 - 0.2;
      plane.position.y = -25;
      camera.position.z = 60;
    }
  
    function initLights() {
      const r = 200;
      const y = 50;
      const lightDistance = 500;
  
      // light = new THREE.AmbientLight(conf.ambientColor);
      // scene.add(light);
  
      // console.log(conf)
      light1 = new THREE.PointLight(conf.light1Color, conf.lightIntensity, lightDistance, 0);
      light1.position.set(0, y, r);
      scene.add(light1);
      light2 = new THREE.PointLight(conf.light2Color, conf.lightIntensity, lightDistance, 0);
      light2.position.set(0, -y, -r);
      scene.add(light2);
      light3 = new THREE.PointLight(conf.light3Color, conf.lightIntensity, lightDistance, 0);
      light3.position.set(r, y, 0);
      scene.add(light3);
      light4 = new THREE.PointLight(conf.light4Color, conf.lightIntensity, lightDistance, 0);
      light4.position.set(-r, y, 0);
      scene.add(light4);
    }
  
    function animate() {
      requestAnimationFrame(animate);
      // console.log(light1.position)

      animatePlane();
      animateLights();
  
      renderer.render(scene, camera);
    };
    animate();


    // gui.add(plane.rotation, "x", 0, Math.PI).name("x axis");
    // gui.add(plane.rotation, "y", 0, Math.PI).name("y axis");
    // gui.add(plane.position, "z", -1000, 1000).name("z axis");
    // gui.add(light1.position, "x", -800, 1600).name("x axis");
    // gui.add(light1.position, "y", -800, 1600).name("y axis");
    // gui.add(light1.position, "z", -800, 1600).name("z axis");

    // console.log(gui)
  
    function animatePlane() {
      gArray = plane.geometry.attributes.position.array;
      const time = Date.now() * 0.0003;
      for (let i = 0; i < gArray.length; i += 3) {
        gArray[i + 2] = simplex(gArray[i] / conf.xyCoef, gArray[i + 1] / conf.xyCoef, time, mouse.x + mouse.y) * conf.zCoef;
      }
      plane.geometry.attributes.position.needsUpdate = true;
      // plane.geometry.computeBoundingSphere();
    }
  
    function animateLights() {
      const time = Date.now() * 0.001;
      const d = 50;
      light1.position.x = Math.sin(time * 0.1) * d;
      light1.position.z = Math.cos(time * 0.2) * d;
      light2.position.x = Math.cos(time * 0.3) * d;
      light2.position.z = Math.sin(time * 0.4) * d;
      light3.position.x = Math.sin(time * 0.5) * d;
      light3.position.z = Math.sin(time * 0.6) * d;
      light4.position.x = Math.sin(time * 0.7) * d;
      light4.position.z = Math.cos(time * 0.8) * d;
    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div 
      id='anim'
      style={{ 
        position: "absolute",
        zIndex: 0,
        bottom: 0,
        width: "calc(100vw - 15px)",
        overflow: "hidden"
      }}>
    </div>
  )
}

export default WaveAnim;