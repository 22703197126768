import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoutes from './protectedRoutes';
import PublicRoutes from './publicRoutes';

import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer/Footer';

//routes
import LandingPage from '../views/landingPage/LandingPage';
import AuthPage from '../views/authPage/AuthPage';
import HomePage from '../views/homePage/HomePage';
import CardsPage from '../views/cardsPage/CardsPage';
import VirtualCardsPage from '../views/virtualCardsPage/VirtualCardsPage';
import TransactionsPage from '../views/transactionsPage/TransactionsPage';

const MainRoutes = () => (
  <Router>
    <Routes>
      
      <Route element={<PublicRoutes />}>
        <Route path="/" exact element={<LandingPage />} />
        <Route path="/auth" exact element={<AuthPage />} />
        <Route path="/register" exact element={<AuthPage />} />
      </Route>

      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<Navigation />}>
          {/* <Route path="/dashboard" element={<HomePage />}/> */}
          <Route path="/cards" element={<CardsPage />}/>
          <Route path="/virtual-cards" element={<VirtualCardsPage />}/>
          {/* <Route path="/transactions" element={<TransactionsPage />}/> */}
        </Route>
      </Route>

    </Routes>
    {/* <Footer /> */}
  </Router>
)

export default MainRoutes