import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const LogIn = (formData, navigate, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.login(formData);
    console.log(data, status);
    if (status === 200) {
      dispatch({ type: actionTypes.LOG_IN, payload: data.tokens });
      toast.success(data.message);
      navigate("/cards");
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error("");
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const Register = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  var res;
  try {
    const { data, status } = await api.register(formData);
    console.log(data, status);
    if (status === 200) {
      res = status;
      dispatch({ type: actionTypes.REGISTER });
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error("");
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
  return res;
};