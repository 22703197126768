import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

import AuthReducers from "./authReducers";
import CardsReducer from "./cardsReducers";
import VirtualCardsReducer from "./virtualCardsReducers";
import TransactionsReducer from "./transactionsReducers";
import MiscellaneousReducer from "./miscellaneousActions";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: AuthReducers,
  card: CardsReducer,
  vcn: VirtualCardsReducer,
  transaction: TransactionsReducer,
  other: MiscellaneousReducer
});

export default persistReducer(persistConfig, rootReducer);