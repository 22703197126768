import * as actionTypes from "../actionTypes.js";

const initialState = {
  vcnList: null, 
  vcnDetails: null
};

const virtualCardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_VCNS:
      return { ...state, vcnList: action.payload };
    case actionTypes.GET_VCN_DETAILS:
      return { ...state, vcnDetails: action.payload };

    default:
      return state;
  }
};

export default virtualCardsReducer;
