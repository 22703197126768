import React from 'react';
import { Button, CircularProgress } from '@mui/material';

import styles from "../../global.scss";

const Button1 = (props) => {
  // type = 1 -> text
  // type = 2 -> contained
  // type = 3 -> outlined
  return (
    props.type === 1
    ?
    <Button 
      onClick={props.loading === true ? null : props.onClick}
      variant={"text"}
      disabled={props.disabled}
      sx={{
        fontFamily: "urbanist", 
        fontSize: "16px", 
        fontWeight: "800",
        color: styles["secondary_dark"],
        borderRadius: "12px",
        paddingTop: 1.2,
        paddingRight: 2,       
        paddingBottom: 1.2,
        paddingLeft: 2,        
        width: props.fullWidth === true ? "100%" : "auto",
        ":hover": {
          backgroundColor: styles["primary"]
        }
      }}>
      {
        props.loading === true
        ?
          <CircularProgress 
            size={28}
            sx={{
              color: styles["secondary_dark"],
            }}
          />
        :
          props.label

      }
    </Button>
    :
      props.type === 2
      ?
      <Button 
        onClick={props.loading === true ? null : props.onClick}
        variant={"contained"}
        disabled={props.disabled}
        sx={{
          fontFamily: "urbanist", 
          fontSize: "16px", 
          fontWeight: "800",
          backgroundColor: styles["secondary_dark"],
          color: styles["white"],
          borderRadius: "12px",
          paddingTop: 1.2,
          paddingRight: 2,       
          paddingBottom: 1.2,
          paddingLeft: 2,        
          width: props.fullWidth === true ? "100%" : "auto",
          ":hover": {
            backgroundColor: styles["secondary_light"]
          }
        }}>
        {
          props.loading === true
          ?
            <CircularProgress 
              size={28}
              sx={{
                color: styles["white"],
              }}
            />
          :
            props.label
        }
      </Button>
      :
        props.type === 3
        ?
        <Button 
          onClick={props.loading === true ? null : props.onClick}
          variant={"outlined"}
          disabled={props.disabled}
          sx={{
            fontFamily: "urbanist", 
            fontSize: "16px", 
            fontWeight: "800",
            borderRadius: "12px",
            paddingTop: 1.2,
            paddingRight: 2,       
            paddingBottom: 1.2,
            color: styles["primary_dark"],
            borderColor: styles["primary_dark"],
            paddingLeft: 2,        
            borderWidth: 2,   
            width: props.fullWidth === true ? "100%" : "auto",
            ":hover": {
              backgroundColor: styles["primary_light"],
              borderColor: styles["secondary"],
              color: styles["secondary"],
              borderWidth: 2
            }
          }}>
          {
            props.loading === true
            ?
              <CircularProgress 
                size={28}
                sx={{
                  color: styles["primary_dark"],
                }}
              />
            :
              props.label
          }
        </Button>
        :
        <Button 
          onClick={props.loading === true ? null : props.onClick}
          variant={"contained"}
          disabled={props.disabled}
          sx={{
            fontFamily: "urbanist", 
            fontSize: "16px", 
            fontWeight: "800",
            backgroundColor: styles["secondary_dark"],
            color: styles["white"],
            borderRadius: "12px",
            paddingTop: 1.2,
            paddingRight: 2,       
            paddingBottom: 1.2,
            paddingLeft: 2,  
            width: props.fullWidth === true ? "100%" : "auto",      
            ":hover": {
              backgroundColor: styles["secondary_light"]
            }
          }}>
          {
            props.loading === true
            ?
              <CircularProgress 
                size={28}
                sx={{
                  color: styles["white"],
                }}
              />
            :
              props.label
          }
        </Button>
  )
}

export default Button1