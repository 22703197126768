import * as actionTypes from "../actionTypes.js";

const initialState = {

};

const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
   

    default:
      return state;
  }
};

export default transactionsReducer;
