import * as actionTypes from "../actionTypes.js";

const initialState = {
  currencyList: null
};

const miscellaneousReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CURRENCIES:
      sessionStorage.setItem("currencyList", JSON.stringify(action.payload));
      return { ...state, currencyList: action.payload };

    default:
      return state;
  }
};

export default miscellaneousReducer;
