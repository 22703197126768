import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  TableFooter,
  TablePagination,
  IconButton,
  CircularProgress
} from '@mui/material';

import TopBar1 from '../../components/topBar/TopBar1';
import TablePaginationActions from '../../components/Utils/TablePaginationActions';

import { GetCards, RequestCard } from '../../redux/actions/cardsActions';

import styles from "../../global.scss";
import "./styles.scss";

const CardsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.card);
  const [loading, setLoading] = useState(false);  

  const [cardsList, setCardsList] = useState([]);
  const [cardsTotal, setCardsTotal] = useState(0);
 
  // ------------- table pagination ----------------
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);  
  };

  useEffect(() => {
    dispatch(GetCards(page+1, rowsPerPage, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page, rowsPerPage]);

  useEffect(() => {
    console.log(state)
    try{
      formatCardsList(state.cardsList.Records || []);
      setCardsTotal(state.cardsList.Total || 0);
    }catch(e){}
  },[state]);

  // ----------------- format rows--------------
  const formatCardsList = (list) => {
    setCardsList(list.filter(i => i.Currency !== null))
  }

  const handleLoadCard = () => {
    dispatch(RequestCard(setLoading)).then(() => {
      dispatch(GetCards(page+1, rowsPerPage, setLoading))
    })
  }

  return (
    <div>
      <TopBar1 
        title={"Real Cards"}
        btnLabel={"Load Card"}
        onClick={handleLoadCard}
        />

      <div className='cards'>
        {
          loading
          ?
          <div className='flexCenterCenterRow' style={{ minHeight: "200px" }}>
            <CircularProgress fontSize={60}/>
          </div>
          :
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "#8f8f8f", fontSize: 13}}>Bank</TableCell>
                <TableCell sx={{ color: "#8f8f8f", fontSize: 13}}>Network</TableCell>
                <TableCell sx={{ color: "#8f8f8f", fontSize: 13}}>Currency</TableCell>
                <TableCell sx={{ color: "#8f8f8f", fontSize: 13}}>Limit</TableCell>
                <TableCell sx={{ color: "#8f8f8f", fontSize: 13}}>Status</TableCell>
                <TableCell sx={{ color: "#8f8f8f", fontSize: 13}}>Last 4 digits</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                cardsList.length === 0
                ?
                <TableRow>
                  <TableCell component="th" sx={{ fontSize: 13, fontWeight: 500, textAlign: 'center', borderBottom: 0}} colSpan={4}>
                    No Card Found
                  </TableCell>
                </TableRow>
                :
                cardsList?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" sx={{ fontSize: 13, fontWeight: 500 }}>HDFC</TableCell>
                  <TableCell component="th" sx={{ fontSize: 13, fontWeight: 500 }}>Master Card</TableCell>
                  <TableCell component="th" sx={{ fontSize: 13, fontWeight: 500 }}>{"USD" || "NA"}</TableCell>
                  <TableCell component="th" sx={{ fontSize: 13, fontWeight: 500 }}>{row.Limit || "NA"}</TableCell>
                  <TableCell component="th" sx={{ fontSize: 13, fontWeight: 500 }}>{row.Status ? "Active" : "Inactive"}</TableCell>
                  <TableCell component="th" sx={{ fontSize: 13, fontWeight: 500 }}>xxxx{row.Last4Digits || "NA"}</TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0}}
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  colSpan={10}
                  count={cardsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        }
      </div>
    </div>
  )
}

export default CardsPage