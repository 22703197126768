import * as actionTypes from "../actionTypes.js";

const initialState = {
  cardsList: null
};

const cardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CARDS:
      return { ...state, cardsList: action.payload };

    default:
      return state;
  }
};

export default cardsReducer;
