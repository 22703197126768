import React from 'react';
import { TextField } from '@mui/material';

const Input1 = (props) => {

  return (
    <TextField 
      variant='outlined'
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      disabled={props.disabled}
      label={props.label}
      name={props.name}
      select={props.select}
      type={props.type ? props.type : "text"}
      sx={{
        borderRadius: "12px",
        width: "100%",
        fontWeight: "800",
      }}
      >
      {
        props.select === true && props.children
      }
    </TextField> 
  )
}

export default Input1